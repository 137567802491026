import { RiShutDownLine } from "react-icons/ri";
import { FaUserPlus } from "react-icons/fa6";
import { Row, Col, Navbar } from "react-bootstrap";
import styled from "styled-components";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { FaUserCog } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { RiHomeGearLine } from "react-icons/ri";
import { useState } from "react";

const NavigationSide = ({
  userDetails,
  setSelectedComponent,
  logout,
  handleShowForCreateRoom,
  handleShowForAssignUser,
}) => {
  const [selected, setSelected] = useState("dashboard");

  const handleChangeSelected = (menu) => {
    setSelected(menu);
  };
  return (
    <div>
      <div
        className="d-flex justify-content-center mt-2"
        style={{ flexDirection: "column" }}
      >
        <div className="d-flex justify-content-center mt-3 p-2">
          <img src="profile.png" width="134px" height="134px" />
        </div>
        <span>
          <StyledComponent>{userDetails?.username}</StyledComponent>
        </span>
      </div>
      <div>
        <Navbar className="d-block mt-3 pt-3">
          <div
            className="cursor"
            onClick={() => {
              setSelectedComponent("dashboard");
              handleChangeSelected("dashboard");
            }}
          >
            <div
              className={`NavbarBrand ${
                selected == "dashboard" ? "selected-nav" : "not-selected"
              }`}
            >
              <Row>
                <Col sm={4} className="d-flex justify-content-center">
                  <IoHome size={30} />
                </Col>
                <Col sm={8}>
                  <span>Dashboard</span>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="cursor"
            onClick={() => {
              handleShowForCreateRoom();
              handleChangeSelected("createroom");
            }}
          >
            <div
              className={`NavbarBrand ${
                selected == "createroom" ? "selected-nav" : "not-selected"
              }`}
            >
              <Row>
                <Col sm={4} className="d-flex justify-content-center">
                  <RiHomeGearLine size={30} />
                </Col>
                <Col sm={8}>
                  <span>Create room</span>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="cursor"
            onClick={() => {
              handleShowForAssignUser("assignUser");
              handleChangeSelected("addUser");
            }}
          >
            <div
              className={`NavbarBrand ${
                selected == "addUser" ? "selected-nav" : "not-selected"
              }`}
            >
              <Row>
                <Col sm={4} className="d-flex justify-content-center">
                  <FaUserPlus size={30} />
                </Col>
                <Col sm={8}>
                  <span>Add user</span>
                </Col>
              </Row>
            </div>
          </div>
          <div
            onClick={() => {
              setSelectedComponent("user-screen");
              handleChangeSelected("manageUser");
            }}
            className="cursor"
          >
            <div
              className={`NavbarBrand ${
                selected == "manageUser" ? "selected-nav" : "not-selected"
              }`}
            >
              <Row>
                <Col sm={4} className="d-flex justify-content-center">
                  <FaUserCog size={30} />
                </Col>
                <Col sm={8}>
                  <span>Manage User</span>
                </Col>
              </Row>
            </div>
          </div>
          <div
            onClick={() => {
              setSelectedComponent("chatroom");
              handleChangeSelected("manageroom");
            }}
            className="cursor"
          >
            <div
              className={`NavbarBrand ${
                selected == "manageroom" ? "selected-nav" : "not-selected"
              }`}
            >
              <Row>
                <Col sm={4} className="d-flex justify-content-center">
                  <IoChatbubbleEllipsesSharp size={30} />
                </Col>
                <Col sm={8}>
                  <span>Manage Rooms</span>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="cursor"
            onClick={() => {
              logout();
              handleChangeSelected("logout");
            }}
          >
            <div
              className={`NavbarBrand ${
                selected == "logout" ? "selected-nav" : "not-selected"
              }`}
            >
              <Row>
                <Col sm={4} className="d-flex justify-content-center">
                  <RiShutDownLine size={30} />
                </Col>
                <Col sm={8}>
                  <span>Logout</span>
                </Col>
              </Row>
            </div>
          </div>
        </Navbar>
      </div>
    </div>
  );
};
export default NavigationSide;

const StyledComponent = styled.div`
  // font-family: "Josefin Sans";
  // font-style: italic;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-size: 25px;
  padding-top: 5px;
`;
