import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useEffect, useState } from "react";
import {
  searchMembersList,
  getRoomUserList,
  removeUserFromRoom,
} from "../apis/UserApis";
import { IoPersonCircleOutline } from "react-icons/io5";
import randomColor from "randomcolor";
const ShowMembersComponent = ({ userDetails, room, roomPrimaryId }) => {
  const [groupMembers, setGroupMembers] = useState();
  const [show, setShow] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const handleClose = () => setShow(false);

  useEffect(() => {
    handleGetUserList();
  }, []);
  const handleSearchMember = (event) => {
    if (event?.length > 3) {
      searchMembersList(event, roomPrimaryId).then((res) => {
        setGroupMembers(res?.data?.data);
      });
    } else {
      setGroupMembers(selectedMember);
    }
  };
  const handleGetUserList = () => {
    if (room) {
      getRoomUserList(room).then((res) => {
        setGroupMembers(res?.data?.data);
        setSelectedMember(res?.data?.data);
      });
    }
  };
  const handleRemoveUser = (id) => {
    if (id && roomPrimaryId) {
      removeUserFromRoom(id, roomPrimaryId).then(() => {
        handleGetUserList();
      });
    }
    setShow(false);
  };

  const handleOpenModal = (item) => {
    setShow(true);
    setSelectedMember(item);
  };
  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col sm={12} className="groupInfoContainer">
          <h3 className="fw m-3" style={{ fontFamily: "Inter, sans-serif" }}>
            Members
          </h3>
          <div className="m-2">
            <Form.Control
              type="serach"
              className="search-input"
              placeholder="Search"
              onChange={(e) => {
                handleSearchMember(e?.target?.value);
              }}
            />
          </div>
          {groupMembers?.map((item) => {
             const color = randomColor({
              luminosity: "light",
              format: "hsla",
              alpha: 0.8,
            });
            return (
              <>
                {item?.users !== null ? (
                  <div className="chatroom">
                    <Row>
                      <Col
                        sm={2}
                        className="d-flex justify-content-md-center pt-2"
                      >
                        <IoPersonCircleOutline size={40} color={color}/>
                      </Col>
                      <Col sm={8} className="align-content-center">
                        <span
                          style={{
                            fontFamily: "Inter, sans-serif",
                            fontSize: " 18px",
                          }}
                        >
                          {item?.users?.username}
                        </span>
                      </Col>
                      <Col sm={2} className="align-content-center">
                        {userDetails?.roles?.label === "admin" &&
                        item?.users?.id !== userDetails?.id ? (
                          <RiDeleteBin6Line
                            color={"red"}
                            size={20}
                            onClick={() => {
                              handleOpenModal(item);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose} className="custom-modal" aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "50px" }}>
          <>
            <p className="fw-bold p-3">
              Are you sure you want to delete {selectedMember?.users?.username}{" "}
              ?
            </p>
            <div className="d-flex justify-content-evenly">
              <Button
                variant="light"
                style={{ border: "2px solid lightgray" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleRemoveUser(selectedMember?.users?.id);
                }}
              >
                Delete
              </Button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ShowMembersComponent;
