import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllRolesAPI, userRegistration } from "../apis/UserApis";
import { Form, Button, Col, Row } from "react-bootstrap";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "2px solid #74c9a9" : "2px solid #74c9a9",
    border: "2px solid #74c9a9",
    borderRadius: "6px",
    padding: "10px",
    marginBottom: "10px",
    width: "100%",
  }),
};

const toastOptions = {
  position: "top-right",
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
};

export default function Register() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleId: "",
  });
  const [roleList, setRoleList] = useState();

  const getAllRolesList = async () => {
    getAllRolesAPI().then((res) => {
      let result = res?.map((v) => {
        return {
          value: v.id,
          label: v.label,
        };
      });
      setRoleList(result);
    });
  };

  useEffect(() => {
    getAllRolesList();
  }, []);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const { password, confirmPassword, username, email, roleId } = userData;
    if (password !== confirmPassword) {
      toast.error(
        "Password and confirm password should be same.",
        toastOptions
      );
      return false;
    } else if (username === "") {
      toast.error("User Name is required", toastOptions);
      return false;
    } else if (password.length < 8) {
      toast.error(
        "Password should be equal or greater than 8 characters.",
        toastOptions
      );
      return false;
    } else if (email === "") {
      toast.error("Email is required.", toastOptions);
      return false;
    } else if (roleId === "") {
      toast.error("Role is required.", toastOptions);
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      const { email, username, password, roleId } = userData;
      userRegistration({ email, username, password, role_id: roleId }).then(
        (res) => {
          if (res?.status == 200) {
            navigate("/");
          }
        }
      );
    }
  };

  return (
    <>
      <FormContainer>
        <div className="registorcontainer">
          <Form onSubmit={(event) => handleSubmit(event)}>
            <div className="brand">
              <IoChatbubbleEllipsesSharp
                color="#026873"
                style={{ height: "50px", width: "60px" }}
              />
              <h1 className="fw-bold">Chat Application</h1>
            </div>
            <Form.Control
              type="text"
              placeholder="Username"
              name="username"
              onChange={(e) => handleChange(e)}
            />
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              onChange={(e) => handleChange(e)}
            />
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              onChange={(e) => handleChange(e)}
            />
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={(e) => handleChange(e)}
            />
            <div>
              <Select
                styles={customStyles}
                options={roleList}
                onChange={(e) => {
                  setUserData({ ...userData, roleId: e?.value });
                }}
              />
              <span>
                Already have an account ? <Link to="/">Login Here</Link>
              </span>
            </div>
            <Row className="justify-content-md-center mt-3">
              <Col md="auto">
                <Button type="submit" className="fw-bold" style={{backgroundColor:"#026873"}}>
                  Create User
                </Button>
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </div>
      </FormContainer>
    </>
  );
}

const FormContainer = styled.div`
  background-color: #eef5f3;
  display: flex;
  justify-content: center;
  height: 100vh;
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    h1 {
      color: black;
      text-transform: uppercase;
    }
  }
  .registorcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 1rem;
    background-color: #ffffff;
    padding: 25px;
    margin: 30px;
    box-shadow: 0 4px 4px 4px #49787d4d;
  }
  input {
    background-color: transparent;
    padding: 1rem;
    border: 0.1rem solid #74c9a9;
    border-radius: 0.4rem;
    color: black;
    width: 100%;
    font-size: 1rem;
    &:focus {
      border: 0.1rem solid #74c9a9;
      outline: none;
    }
  }
  select {
    background-color: transparent;
    padding: 1rem;
    border: 0.1rem solid #74c9a9;
    border-radius: 0.4rem;
    color: black;
    width: 100%;
    font-size: 1rem;
    &:focus {
      border: 0.1rem solid #74c9a9;
      outline: none;
    }
  }
  button {
    background-color: #026873;
    color: white;
    padding: 1rem 2rem;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 2rem;
    font-size: 1rem;
    &:hover {
      background-color: #026873;
    }
  }
  span {
    color: #000000;
    a {
      color: #04bf8a;
      text-decoration: none;
    }
  }
`;
