import { useEffect, useState } from "react";
import {
  Card,
  Container,
  Form,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { getAllUserWithRooms } from "../apis/UserApis";
import ShowChatRooms from "./ShowChatRooms";

const ManageUserScreen = ({ userDetails }) => {
  const [userData, setUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setUserId(id);
  };
  useEffect(() => {
    getAllUserWithRooms("all").then((res) => {
      const roomMap = res.reduce((acc, res) => {
        if (!acc[res.id]) {
          acc[res.id] = {
            id: res.id,
            username: res.username,
            room_id: new Set(),
            email: res.email,
          };
        }
        acc[res.id].room_id.add(res.room_id);
        return acc;
      }, {});
      let result = Object.values(roomMap).map((room) => ({
        ...room,
        room_id: Array.from(room.room_id).join(", "),
      }));
      mappedChatroomData(result);
    });
  }, []);

  const mappedChatroomData = (mapData) => {
    let data = mapData?.map((item, index) => {
      const roomsList = item?.room_id
        ?.split(",")
        .map((room_id) => room_id.trim());
      return {
        id: index + 1,
        userId: item?.id,
        username: item?.username,
        email: item?.email,
        rooms:
          item?.room_id == "" || item?.room_id == null
            ? "No"
            : roomsList?.length,
      };
    });
    setUserData(data);
  };

  const filteredData = userData.filter((item) =>
    searchTerm === ""
      ? true
      : item.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const tableCustomStyles = {
    striped: {
      backgroundColor: "#eef5f3e6 !important",
    },
    header: {
      style: {
        fontSize: "14px !important",
        fontWeight: "600 !important",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        textAlign: "center",
      },
    },
  };
  createTheme("custom", {
    background: {
      default: "#eef5f3e6",
    },
    striped: {
      default: "white",
    },
  });

  const UsersColumns = [
    {
      name: <span className="fw-bold">SR NO</span>,
      selector: (row) => <span className="fw-bold">{row.id}</span>,
      sortable: true,
      width: "10%",
      center: true,
    },
    {
      name: <span className="fw-bold">USER NAME</span>,
      selector: (row) => row.username,
      sortable: true,
      width: "35%",
      center: true,
    },
    {
      name: <span className="fw-bold">EMAIL</span>,
      selector: (row) => row.email,
      sortable: true,
      width: "25%",
    },
    {
      name: <span className="fw-bold">CHATROOMS</span>,
      selector: (row) =>
        row?.rooms == "No" ? (
          <span>no any chats</span>
        ) : (
          <Button
            style={{
              backgroundColor: "#38767C",
              padding: "0 10px",
              borderRadius: "15px",
            }}
            onClick={() => {
              handleShow(row?.userId);
            }}
          >
            {row?.rooms}
          </Button>
        ),
      sortable: true,
      width: "20%",
    },
  ];
  return (
    <Container>
      <div className="pt-2">
        <h2 className="fw pt-3 m-2" style={{ fontFamily: "Inter, sans-serif" }}>
          Manage Users
        </h2>
        <Card style={{ backgroundColor: "#eef5f3e6" }}>
          <Card.Body className="p-0">
            <Row
              className="m-0"
              style={{
                padding: "20px 0 0 0",
              }}
            >
              <Col sm={4} className="d-flex justify-content-center m-0">
                <Form.Control
                  type="search"
                  className="m-1"
                  placeholder="Search By name "
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Col>
            </Row>
            <DataTable
              columns={UsersColumns}
              data={filteredData}
              pagination
              responsive
              highlightOnHover
              striped
              customStyles={tableCustomStyles}
              theme="custom"
            />
          </Card.Body>
        </Card>
      </div>
      <Modal show={show} onHide={handleClose} className=" p-3">
        <ShowChatRooms userId={userId} userDetails={userDetails}/>
      </Modal>
    </Container>
  );
};
export default ManageUserScreen;
