// export const host = "http://localhost:3001/";
export const host = "https://stgchatbe.karmaalab.com/";

export const loginRoute = `${host}api/user/auth/login`;
export const registerRoute = `${host}api/user/create`;
export const allUsersRoute = `${host}api/user/get`;
export const allUsersByRoom = `${host}api/user/room`;
export const createRoomRoute = `${host}api/user/room/create`;
export const getAllRoles = `${host}api/user/roles/get`;
export const addMessage = `${host}api/message/add`;
export const getMessage = `${host}api/message/get`;
export const getRooms = `${host}api/user/room/get`;
export const updateUserRoute = `${host}api/user/update`;
export const deleteUser = `${host}api/user/delete`;
export const deactiveRoom = `${host}api/user/room/deactive`;
export const searchRoom = `${host}api/user/room/filter`;
export const getUserInRooms = `${host}api/user/room/user/get`;
export const removeUserRoom = `${host}api/user/room/remove`;
export const searchMembers = `${host}api/user/room/filter/members`;
export const addNotification = `${host}api/notification/add`;
export const getNotification = `${host}api/notification/get`;
export const removeNotifi = `${host}api/notification/remove`;
export const getRoomChat = `${host}api/user/room/all`;
export const getUsersRooms = `${host}api/user/room-list`;

