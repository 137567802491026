import { Row, Col, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getAllRoomAPI, searchRoomList } from "../apis/UserApis";
import { IoPersonCircleOutline } from "react-icons/io5";
import randomColor from "randomcolor";

const ShowChatRooms = ({ userId, userDetails }) => {
  const [chatRooms, setChatrooms] = useState();
  const [selectRooms, setSelectChatrooms] = useState();

  useEffect(() => {
    getAllRoomAPI(userId).then((res) => {
      setChatrooms(res);
      setSelectChatrooms(res);
    });
  }, []);

  const handleSearchChatRoom = (event) => {
    if (event?.length > 3) {
      searchRoomList(event, userId, userDetails?.roles?.label).then((res) => {
        setChatrooms(res?.data?.data);
      });
    } else {
        setChatrooms(selectRooms);
    }
  };
  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col sm={12} className="groupInfoContainer">
          <h1 className="fw m-3" style={{ fontFamily: "Inter, sans-serif" }}>
            Chat Rooms
          </h1>
          <div className="m-2">
            <Form.Control
              type="serach"
              className="search-input"
              placeholder="Search"
              onChange={(e) => {
                handleSearchChatRoom(e?.target?.value);
              }}
            />
          </div>
          {chatRooms?.map((item) => {
            const color = randomColor({
              luminosity: "light",
              format: "hsla",
              alpha: 0.8,
            });
            return (
              <>
                {item?.rooms !== null ? (
                  <div className="chatroom">
                    <Row>
                      <Col
                        sm={2}
                        className="d-flex justify-content-md-center pt-2"
                      >
                        <IoPersonCircleOutline size={40} color={color}/>
                      </Col>
                      <Col sm={10} className="align-content-center">
                        <span
                          style={{
                            fontFamily: "Inter, sans-serif",
                            fontSize: " x-large",
                          }}
                        >
                          {item?.rooms?.room_name || item?.room_name}
                        </span>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Col>
      </Row>
    </>
  );
};
export default ShowChatRooms;
