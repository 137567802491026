import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Chats from "./Chats";
import ChatRooms from "./ChatRooms";
import { getAllRoomAPI, getAllUsersList } from "../apis/UserApis";
import { useEffect, useState } from "react";
import { RiShutDownLine } from "react-icons/ri";
import styled from "styled-components";
import { WelcomeScreen } from "./WelcomeScreen";

const DashboradForUserScreen = ({
  handleJoinRoom,
  logout,
  socket,
  userDetails,
}) => {
  const [roomListForUser, setRoomListForUser] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [roomName, setRoomName] = useState("");
  const [roomId, setRoomId] = useState("");
  const [roomPrimaryId, setRoomPrimaryId] = useState();
  const [isChatShow, setIsChatShow] = useState(false);
  const [roomDetails, setRoomDetails] = useState();
  const [color, setColor] = useState();

  useEffect(() => {
    const userData = sessionStorage.getItem("userDetails");
    const userDetail = JSON.parse(userData);
    getAllUsersList(userDetail?.id).then((data) => {
      getAllRoomAPI(data?.id).then((res) => {
        setRoomId(roomListForUser[0]?.rooms?.room_id);
        setRoomListForUser(res);
      });
    });
  }, []);

  const handleRefresh = (item,color) => {
    setColor(color)
    setIsChatShow(true);
    setRoomDetails(item?.rooms);
    setRoomPrimaryId(item?.room_id);
    setRoomName(item?.rooms?.room_name);
    setRoomId(item?.rooms?.room_id);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleShowProfile = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <StyledComponent>{userDetails?.username}</StyledComponent>
    </Tooltip>
  );

  return (
    <>
      <Row className="m-0" style={{ height: "100vh" }}>
        <Col sm={1}>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={handleShowProfile}
          >
            <img
              src="profile.png"
              width="100x"
              height="100px"
              className="cursor"
            />
          </OverlayTrigger>
        </Col>
         <Col sm={4}  className="align-content-center">
          <Row>
            <Col sm={12}>
              <ChatRooms
                roomListForUser={roomListForUser}
                handleJoinRoom={handleJoinRoom}
                handleRefresh={handleRefresh}
                userDetails={userDetails}
                roomPrimaryId={roomPrimaryId}
                socket={socket}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={6} className="align-content-center">
          <Row>
            <Col sm={12}>
              {isChatShow == true ? (
                <Chats
                  socket={socket}
                  userDetails={userDetails}
                  room={roomId}
                  roomName={roomName}
                  key={refreshKey}
                  roomPrimaryId={roomPrimaryId}
                  roomDetails={roomDetails}
                  color={color}
                />
              ) : (
                <WelcomeScreen />
              )}
            </Col>
          </Row>
        </Col> 
        <Col sm={1}>
          <div className="d-flex justify-content-center">
            <Button
              onClick={logout}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <RiShutDownLine size={40} color="#0B405E" />
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DashboradForUserScreen;
const StyledComponent = styled.div`
  font-family: "Josefin Sans";
  font-style: italic;
  padding: 10px;
  background:#ffffff
  color: #000000;
  font-size: 25px;
`;
