export const WelcomeScreen = () => {
  return (
    <div className="welcomecontainer">
      <div className="welcomeCard">
        <img src="Work chat-cuate 1.png" height="337px" width="407px" />
        <br />
        <span className="text-center fw-bold mt-2">
          Welcome to the Chatroom
        </span>
      </div>
    </div>
  );
};
